import React from "react";
import { isMobile, whichBrowser } from "src/utils/browser";
import { currencyFromIp } from "src/utils/currency.js";
import { getLanguageField } from "src/utils/language";
import { getCurrentCurrency, getCurrentUnits, setCurrentCurrency, setCurrentUnits } from "src/utils/localStorage";

interface ApplicationContextProviderProps {
  /** current destination */
  destinationId: string;
  /** default currency */
  defaultCurrency: string;
  /** current language */
  language: string;
  /** default language */
  defaultLanguage: string;
  /** is in app mode */
  isInApp: boolean;
  /** application nodes */
  children?: React.ReactNode;
}

interface ApplicationContextProviderState {
  context: IApplicationContext;
  literals: Record<string, string> | undefined;
}

/** Pre-props initial context: */
const initialContextValues: Partial<IApplicationContext> = {
  currency: undefined,
  defaultCurrency: undefined,
  units: undefined,
  destinationId: process.env.CLIENT,
  translate: () => '-',
  literal: () => '-',
}

/**
 * Initial context.
 */
const ApplicationContext = React.createContext<IApplicationContext>(initialContextValues as any);

/**
 * Application Context Provider
 */
class ApplicationContextProvider extends React.Component<ApplicationContextProviderProps, ApplicationContextProviderState> {

  /** helper functions: */
  public helpers = {
    literal: (key: string) => {
      return (this.state.literals || {})[key] || '-';
    },
    translate: (translations: TTranslations<any> = [], field: string, isHtml: boolean = false, mapHtml: boolean = false, htmlClass?: string) => {
      return getLanguageField(translations, this.props.language, field, isHtml, mapHtml, htmlClass);
    },
  }

  /** state modifiers: */
  public setters = {
    setCurrency: (newCurrency: string) => {
      const next_context = { ...this.state.context, currency: newCurrency, ...this.setters, };
      setCurrentCurrency(newCurrency);
      this.setState({context: next_context})
    },
    setUnitSystem: (newUnitSystem: TUnits) => {
      const next_context = { ...this.state.context, units: newUnitSystem, ...this.setters, };
      setCurrentUnits(newUnitSystem);
      this.setState({context: next_context});
    },
  }

  /** initial state */
  public state: ApplicationContextProviderState = {
    context: {
      ready: false,
      destinationId: this.props.destinationId,
      currency: getCurrentCurrency(this.props.defaultCurrency),
      defaultCurrency: this.props.defaultCurrency,
      units: getCurrentUnits(undefined),
      browser: 'unknown',
      isMobile: false,
      isInApp: this.props.isInApp,
      language: this.props.language,
      defaultLanguage: this.props.defaultLanguage,
      ...this.setters,
      ...this.helpers,
    },
    literals: undefined,
  }

  /** Populate context values with run-time values: */
  async componentDidMount() {
    //load currency from ip
    const currency = await currencyFromIp() || this.state.context.currency;
    React.startTransition(() => {
      this
      .loadLocale()
      .then(() => {
        this.setState({
          context: {
            ...this.state.context,
            currency,
            ready: true,
            browser: whichBrowser(),
            isMobile: isMobile(),
          }
        })
      });
    })
  }

  async componentDidUpdate(prevProps: Readonly<ApplicationContextProviderProps>, prevState: Readonly<ApplicationContextProviderState>, snapshot?: any) {
    const languageChanged = prevProps.language !== this.props.language;
    if (languageChanged) {
      await this.loadLocale();
      this.setState({ context: { ...this.state.context, language: this.props.language }})
     }
  }

  // ugly - yes - dynamic imports not possible.
  async loadLocale () {
    const langCode: string = this.props.language;
    const imports: any = {
      "en-US": async () => await import("./../../src-dynamic/translations.en-US.js"),
      "nb-NO": async () => await import("./../../src-dynamic/translations.nb-NO.js"),
      "no-NO": async () => await import("./../../src-dynamic/translations.nb-NO.js"),
      "es-ES": async () => await import("./../../src-dynamic/translations.es-ES.js"),
      "fr-FR": async () => await import("./../../src-dynamic/translations.fr-FR.js"),
      "it-IT": async () => await import("./../../src-dynamic/translations.it-IT.js"),
      "de-DE": async () => await import("./../../src-dynamic/translations.de-DE.js"),
    }
    try {
      const literal_data = await imports[langCode]();
      this.setState({literals: literal_data});
    } catch (err) {
      try {
        const literal_data = await imports[langCode]();
        this.setState({literals: literal_data});
      } catch (err) {
        try {
          const literal_data = await imports["en-US"]();
          this.setState({literals: literal_data});
        } catch (err) {}
      }
    }
  }

  /** render provider */
  render() {
    return (
      <ApplicationContext.Provider value={this.state.context}>
        {this.props.children}
      </ApplicationContext.Provider>
    );
  }
}


export { ApplicationContextProvider as default, ApplicationContextProvider, ApplicationContext }
